export enum Urls {
  Account = "account",
  AccountSettings = "settings",
  ActivateToken = "activate-token",
  Billing = "settings/billing",
  Clusters = "clusters",
  CoiledProPage = "go-coiled-pro",
  CoiledStaff = "staff",
  Credentials = "credentials",
  ForgotPassword = "password/forgot",
  GetStarted = "get-started",
  GithubInitLogin = "users/github/login",
  GithubLogin = "users/github",
  GoogleInitLogin = "users/google/login",
  GoogleLogin = "users/google",
  Infrastructure = "infrastructure",
  Login = "login",
  NewBilling = "settings/new-billing",
  Organizations = "organizations",
  Profile = "profile",
  ResetPassword = "password/reset",
  RetrieveUsername = "username/forgot",
  Root = "/",
  Setup = "settings/setup",
  SignUp = "signup",
  Software = "software",
  Team = "team",
  Usage = "usage",
  UserInfoForm = "user-info-form",
  VerifyEmailConfirm = "verify/confirm",
  VerifyEmailReSend = "verify/resend",
  VerifyEmailSent = "verify/sent",
  Workspaces = "workspaces",
}
