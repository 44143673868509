/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JobFileSchema } from './JobFileSchema';
import {
    JobFileSchemaFromJSON,
    JobFileSchemaFromJSONTyped,
    JobFileSchemaToJSON,
} from './JobFileSchema';

/**
 * 
 * @export
 * @interface CreateBatchJobSchema
 */
export interface CreateBatchJobSchema {
    /**
     * 
     * @type {string}
     * @memberof CreateBatchJobSchema
     */
    workspace: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBatchJobSchema
     */
    userCommand: string;
    /**
     * 
     * @type {Array<JobFileSchema>}
     * @memberof CreateBatchJobSchema
     */
    userFiles: Array<JobFileSchema>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateBatchJobSchema
     */
    taskArray: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateBatchJobSchema
     */
    schedulerTaskArray?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBatchJobSchema
     */
    runOnScheduler?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBatchJobSchema
     */
    runOnWorkers?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateBatchJobSchema
     */
    envVars?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateBatchJobSchema
     */
    secretEnvVars?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CreateBatchJobSchema
     */
    workdir?: string;
}

/**
 * Check if a given object implements the CreateBatchJobSchema interface.
 */
export function instanceOfCreateBatchJobSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "workspace" in value;
    isInstance = isInstance && "userCommand" in value;
    isInstance = isInstance && "userFiles" in value;
    isInstance = isInstance && "taskArray" in value;

    return isInstance;
}

export function CreateBatchJobSchemaFromJSON(json: any): CreateBatchJobSchema {
    return CreateBatchJobSchemaFromJSONTyped(json, false);
}

export function CreateBatchJobSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBatchJobSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspace': json['workspace'],
        'userCommand': json['user_command'],
        'userFiles': ((json['user_files'] as Array<any>).map(JobFileSchemaFromJSON)),
        'taskArray': json['task_array'],
        'schedulerTaskArray': !exists(json, 'scheduler_task_array') ? undefined : json['scheduler_task_array'],
        'runOnScheduler': !exists(json, 'run_on_scheduler') ? undefined : json['run_on_scheduler'],
        'runOnWorkers': !exists(json, 'run_on_workers') ? undefined : json['run_on_workers'],
        'envVars': !exists(json, 'env_vars') ? undefined : json['env_vars'],
        'secretEnvVars': !exists(json, 'secret_env_vars') ? undefined : json['secret_env_vars'],
        'workdir': !exists(json, 'workdir') ? undefined : json['workdir'],
    };
}

export function CreateBatchJobSchemaToJSON(value?: CreateBatchJobSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspace': value.workspace,
        'user_command': value.userCommand,
        'user_files': ((value.userFiles as Array<any>).map(JobFileSchemaToJSON)),
        'task_array': value.taskArray,
        'scheduler_task_array': value.schedulerTaskArray,
        'run_on_scheduler': value.runOnScheduler,
        'run_on_workers': value.runOnWorkers,
        'env_vars': value.envVars,
        'secret_env_vars': value.secretEnvVars,
        'workdir': value.workdir,
    };
}

