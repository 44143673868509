/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressSchema
 */
export interface AddressSchema {
    /**
     * 
     * @type {string}
     * @memberof AddressSchema
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressSchema
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressSchema
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressSchema
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressSchema
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressSchema
     */
    postalCode?: string;
}

/**
 * Check if a given object implements the AddressSchema interface.
 */
export function instanceOfAddressSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddressSchemaFromJSON(json: any): AddressSchema {
    return AddressSchemaFromJSONTyped(json, false);
}

export function AddressSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'line1': !exists(json, 'line1') ? undefined : json['line1'],
        'line2': !exists(json, 'line2') ? undefined : json['line2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'postalCode': !exists(json, 'postal_code') ? undefined : json['postal_code'],
    };
}

export function AddressSchemaToJSON(value?: AddressSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'line1': value.line1,
        'line2': value.line2,
        'city': value.city,
        'state': value.state,
        'country': value.country,
        'postal_code': value.postalCode,
    };
}

