/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DockerNetworkVolume } from './DockerNetworkVolume';
import {
    DockerNetworkVolumeFromJSON,
    DockerNetworkVolumeFromJSONTyped,
    DockerNetworkVolumeToJSON,
} from './DockerNetworkVolume';
import type { FirewallRuleTarget } from './FirewallRuleTarget';
import {
    FirewallRuleTargetFromJSON,
    FirewallRuleTargetFromJSONTyped,
    FirewallRuleTargetToJSON,
} from './FirewallRuleTarget';
import type { FirewallSpec } from './FirewallSpec';
import {
    FirewallSpecFromJSON,
    FirewallSpecFromJSONTyped,
    FirewallSpecToJSON,
} from './FirewallSpec';

/**
 * Schema that includes all keys for both AWS and GCP.
 * 
 * This is a hack because otherwise the data from client will always be converted with AWS schema,
 * so any GCP specific-keys data will be lost.
 * 
 * In cluster_options_service.create, we convert this to the specific schema based on whether using AWS/GCP backend.
 * @export
 * @interface SupersetRuntimeOptionsAPISchema
 */
export interface SupersetRuntimeOptionsAPISchema {
    /**
     * 
     * @type {string}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    regionName?: string;
    /**
     * 
     * @type {string}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    zoneName?: string;
    /**
     * 
     * @type {FirewallRuleTarget}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    firewall?: FirewallRuleTarget;
    /**
     * 
     * @type {FirewallSpec}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    firewallSpec?: FirewallSpec;
    /**
     * 
     * @type {boolean}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    spot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    spotOnDemandFallback?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    multizone?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    multizoneAllowCrossZone?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    useDashboardPublicIp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    useDashboardHttps?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    sendPrometheusMetrics?: boolean;
    /**
     * 
     * @type {object}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    prometheusWrite?: object;
    /**
     * 
     * @type {boolean}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    usePlacementGroup?: boolean;
    /**
     * 
     * @type {Array<DockerNetworkVolume>}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    networkVolumes?: Array<DockerNetworkVolume>;
    /**
     * 
     * @type {string}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    dockerShmSize?: string;
    /**
     * 
     * @type {string}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    keypairName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    spotReplacement?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    schedulerAcceleratorCount?: number;
    /**
     * 
     * @type {string}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    schedulerAcceleratorType?: string;
    /**
     * 
     * @type {number}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    workerAcceleratorCount?: number;
    /**
     * 
     * @type {string}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    workerAcceleratorType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    schedulerEphemeralOsDisk?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupersetRuntimeOptionsAPISchema
     */
    workerEphemeralOsDisk?: boolean;
}

/**
 * Check if a given object implements the SupersetRuntimeOptionsAPISchema interface.
 */
export function instanceOfSupersetRuntimeOptionsAPISchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupersetRuntimeOptionsAPISchemaFromJSON(json: any): SupersetRuntimeOptionsAPISchema {
    return SupersetRuntimeOptionsAPISchemaFromJSONTyped(json, false);
}

export function SupersetRuntimeOptionsAPISchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupersetRuntimeOptionsAPISchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regionName': !exists(json, 'region_name') ? undefined : json['region_name'],
        'zoneName': !exists(json, 'zone_name') ? undefined : json['zone_name'],
        'firewall': !exists(json, 'firewall') ? undefined : FirewallRuleTargetFromJSON(json['firewall']),
        'firewallSpec': !exists(json, 'firewall_spec') ? undefined : FirewallSpecFromJSON(json['firewall_spec']),
        'spot': !exists(json, 'spot') ? undefined : json['spot'],
        'spotOnDemandFallback': !exists(json, 'spot_on_demand_fallback') ? undefined : json['spot_on_demand_fallback'],
        'multizone': !exists(json, 'multizone') ? undefined : json['multizone'],
        'multizoneAllowCrossZone': !exists(json, 'multizone_allow_cross_zone') ? undefined : json['multizone_allow_cross_zone'],
        'useDashboardPublicIp': !exists(json, 'use_dashboard_public_ip') ? undefined : json['use_dashboard_public_ip'],
        'useDashboardHttps': !exists(json, 'use_dashboard_https') ? undefined : json['use_dashboard_https'],
        'sendPrometheusMetrics': !exists(json, 'send_prometheus_metrics') ? undefined : json['send_prometheus_metrics'],
        'prometheusWrite': !exists(json, 'prometheus_write') ? undefined : json['prometheus_write'],
        'usePlacementGroup': !exists(json, 'use_placement_group') ? undefined : json['use_placement_group'],
        'networkVolumes': !exists(json, 'network_volumes') ? undefined : ((json['network_volumes'] as Array<any>).map(DockerNetworkVolumeFromJSON)),
        'dockerShmSize': !exists(json, 'docker_shm_size') ? undefined : json['docker_shm_size'],
        'keypairName': !exists(json, 'keypair_name') ? undefined : json['keypair_name'],
        'spotReplacement': !exists(json, 'spot_replacement') ? undefined : json['spot_replacement'],
        'schedulerAcceleratorCount': !exists(json, 'scheduler_accelerator_count') ? undefined : json['scheduler_accelerator_count'],
        'schedulerAcceleratorType': !exists(json, 'scheduler_accelerator_type') ? undefined : json['scheduler_accelerator_type'],
        'workerAcceleratorCount': !exists(json, 'worker_accelerator_count') ? undefined : json['worker_accelerator_count'],
        'workerAcceleratorType': !exists(json, 'worker_accelerator_type') ? undefined : json['worker_accelerator_type'],
        'schedulerEphemeralOsDisk': !exists(json, 'scheduler_ephemeral_os_disk') ? undefined : json['scheduler_ephemeral_os_disk'],
        'workerEphemeralOsDisk': !exists(json, 'worker_ephemeral_os_disk') ? undefined : json['worker_ephemeral_os_disk'],
    };
}

export function SupersetRuntimeOptionsAPISchemaToJSON(value?: SupersetRuntimeOptionsAPISchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region_name': value.regionName,
        'zone_name': value.zoneName,
        'firewall': FirewallRuleTargetToJSON(value.firewall),
        'firewall_spec': FirewallSpecToJSON(value.firewallSpec),
        'spot': value.spot,
        'spot_on_demand_fallback': value.spotOnDemandFallback,
        'multizone': value.multizone,
        'multizone_allow_cross_zone': value.multizoneAllowCrossZone,
        'use_dashboard_public_ip': value.useDashboardPublicIp,
        'use_dashboard_https': value.useDashboardHttps,
        'send_prometheus_metrics': value.sendPrometheusMetrics,
        'prometheus_write': value.prometheusWrite,
        'use_placement_group': value.usePlacementGroup,
        'network_volumes': value.networkVolumes === undefined ? undefined : ((value.networkVolumes as Array<any>).map(DockerNetworkVolumeToJSON)),
        'docker_shm_size': value.dockerShmSize,
        'keypair_name': value.keypairName,
        'spot_replacement': value.spotReplacement,
        'scheduler_accelerator_count': value.schedulerAcceleratorCount,
        'scheduler_accelerator_type': value.schedulerAcceleratorType,
        'worker_accelerator_count': value.workerAcceleratorCount,
        'worker_accelerator_type': value.workerAcceleratorType,
        'scheduler_ephemeral_os_disk': value.schedulerEphemeralOsDisk,
        'worker_ephemeral_os_disk': value.workerEphemeralOsDisk,
    };
}

