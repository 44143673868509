/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingPeriodSchema
 */
export interface BillingPeriodSchema {
    /**
     * 
     * @type {Date}
     * @memberof BillingPeriodSchema
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BillingPeriodSchema
     */
    endDate?: Date;
}

/**
 * Check if a given object implements the BillingPeriodSchema interface.
 */
export function instanceOfBillingPeriodSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BillingPeriodSchemaFromJSON(json: any): BillingPeriodSchema {
    return BillingPeriodSchemaFromJSONTyped(json, false);
}

export function BillingPeriodSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingPeriodSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': !exists(json, 'start_date') ? undefined : (new Date(json['start_date'])),
        'endDate': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'])),
    };
}

export function BillingPeriodSchemaToJSON(value?: BillingPeriodSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': value.startDate === undefined ? undefined : (value.startDate.toISOString().substring(0,10)),
        'end_date': value.endDate === undefined ? undefined : (value.endDate.toISOString().substring(0,10)),
    };
}

