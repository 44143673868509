import React from "react";
import { useProgram, useProgramUsage } from "../../crud/pricing/hooks";
import { SimpleTable } from "../../shared-components/SimpleTable";

type OrganizationDetailsProps = {
  organizationId: number;
};
export const StaffOrganizationDetails = ({
  organizationId,
}: OrganizationDetailsProps): React.ReactElement => {
  const { data: program, isLoading: programLoading } =
    useProgram(organizationId);
  const { data: programUsage, isLoading: programUsageLoading } =
    useProgramUsage(organizationId);
  return (
    <div>
      {organizationId && (
        <SimpleTable
          loading={programLoading || programUsageLoading}
          text={[
            ["Coiled Credit Value", program?.coiledCreditValue],
            ["Credit Allotment", program?.creditAllotment],
            ["Renew Date", program?.renewDate?.toLocaleDateString()],
            ["Current Credit Balance", programUsage?.currentCredits],
            ["Credit Spend This Month", programUsage?.programCreditsSpent],
          ]}
        />
      )}
      {!organizationId && <>No Program</>}
    </div>
  );
};
