/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BatchJobTaskResponse } from './BatchJobTaskResponse';
import {
    BatchJobTaskResponseFromJSON,
    BatchJobTaskResponseFromJSONTyped,
    BatchJobTaskResponseToJSON,
} from './BatchJobTaskResponse';
import type { JobStateEnum } from './JobStateEnum';
import {
    JobStateEnumFromJSON,
    JobStateEnumFromJSONTyped,
    JobStateEnumToJSON,
} from './JobStateEnum';

/**
 * 
 * @export
 * @interface BatchJobStatusResponse
 */
export interface BatchJobStatusResponse {
    /**
     * 
     * @type {number}
     * @memberof BatchJobStatusResponse
     */
    id: number;
    /**
     * 
     * @type {JobStateEnum}
     * @memberof BatchJobStatusResponse
     */
    state: JobStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof BatchJobStatusResponse
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof BatchJobStatusResponse
     */
    started?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BatchJobStatusResponse
     */
    completed?: Date;
    /**
     * 
     * @type {number}
     * @memberof BatchJobStatusResponse
     */
    clusterId?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchJobStatusResponse
     */
    clusterState?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchJobStatusResponse
     */
    container?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchJobStatusResponse
     */
    userCommand: string;
    /**
     * 
     * @type {string}
     * @memberof BatchJobStatusResponse
     */
    command?: string;
    /**
     * 
     * @type {Array<BatchJobTaskResponse>}
     * @memberof BatchJobStatusResponse
     */
    tasks: Array<BatchJobTaskResponse>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BatchJobStatusResponse
     */
    envVars?: { [key: string]: string; };
}

/**
 * Check if a given object implements the BatchJobStatusResponse interface.
 */
export function instanceOfBatchJobStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "userCommand" in value;
    isInstance = isInstance && "tasks" in value;

    return isInstance;
}

export function BatchJobStatusResponseFromJSON(json: any): BatchJobStatusResponse {
    return BatchJobStatusResponseFromJSONTyped(json, false);
}

export function BatchJobStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchJobStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': JobStateEnumFromJSON(json['state']),
        'created': (new Date(json['created'])),
        'started': !exists(json, 'started') ? undefined : (new Date(json['started'])),
        'completed': !exists(json, 'completed') ? undefined : (new Date(json['completed'])),
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'clusterState': !exists(json, 'cluster_state') ? undefined : json['cluster_state'],
        'container': !exists(json, 'container') ? undefined : json['container'],
        'userCommand': json['user_command'],
        'command': !exists(json, 'command') ? undefined : json['command'],
        'tasks': ((json['tasks'] as Array<any>).map(BatchJobTaskResponseFromJSON)),
        'envVars': !exists(json, 'env_vars') ? undefined : json['env_vars'],
    };
}

export function BatchJobStatusResponseToJSON(value?: BatchJobStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'state': JobStateEnumToJSON(value.state),
        'created': (value.created.toISOString()),
        'started': value.started === undefined ? undefined : (value.started.toISOString()),
        'completed': value.completed === undefined ? undefined : (value.completed.toISOString()),
        'cluster_id': value.clusterId,
        'cluster_state': value.clusterState,
        'container': value.container,
        'user_command': value.userCommand,
        'command': value.command,
        'tasks': ((value.tasks as Array<any>).map(BatchJobTaskResponseToJSON)),
        'env_vars': value.envVars,
    };
}

