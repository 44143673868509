import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { ProgramUtilization } from "../../domain/billing";
import { Urls } from "../../domain/urls";
import { StyledAlert } from "./StyledAlert";
import { WorkspaceContext } from "../../utils/context";
import {
  useProgram,
  useProgramUsage,
  useWorkspaceProgramUsage,
} from "../../crud/pricing/hooks";
import { useWorkspaceContextSlug } from "../../utils/hooks";
import { useIsOrgAdmin } from "../../crud/account/hooks";

// determine the severity of the alert to show to the user (if any)
const determineAlertLevel = (classification: ProgramUtilization) => {
  if (classification >= ProgramUtilization.ReachedQuota) {
    return "error";
  }
  return "warning";
};

type Props = {
  organizationId: number;
};

const HandleProgramCreditLevels = ({
  organizationId,
}: Props): React.ReactElement | null => {
  const billingUrl = useBillingUrl();
  const { data: programUsage, isSuccess: usageLoaded } =
    useProgramUsage(organizationId);
  const { data: program, isSuccess: programLoaded } =
    useProgram(organizationId);
  const isAdmin = useIsOrgAdmin(organizationId);
  if (!usageLoaded || !programLoaded) {
    return null;
  }
  const usagePercent = programUsage.programCreditsUsagePercent;
  if (usagePercent < ProgramUtilization.High) {
    return null;
  }
  const alertLevel = determineAlertLevel(usagePercent);
  const percent = usagePercent.toFixed(1).toString();
  let message;
  const upgradeMessage = (
    <>
      {isAdmin ? "You" : "Your org admin"} can{" "}
      <Link component={NavLink} to={billingUrl}>
        {!program.billingMethod
          ? "add a credit card"
          : "increase your organization's limit"}
      </Link>{" "}
      to allow using more.
    </>
  );
  if (usagePercent >= ProgramUtilization.ReachedQuota) {
    message = (
      <>
        {`Your organization has run out of Coiled credits. Any running`}
        {` clusters will be stopped shortly. Your Coiled credit balance will`}
        {` renew on ${program.renewDate.toLocaleDateString()}. `}
        {upgradeMessage}
      </>
    );
  } else if (usagePercent >= ProgramUtilization.High) {
    message = (
      <>
        {`Your organization is ${percent}% through your available Coiled credits`}
        {` which will renew on ${program.renewDate.toLocaleDateString()}. `}
        {upgradeMessage}
      </>
    );
  }

  return (
    <StyledAlert title="Monthly Plan" message={message} severity={alertLevel} />
  );
};

const HandleWorkspaceCreditLevels = ({
  organizationId,
}: Props): React.ReactElement | null => {
  const viewedAccount = useContext(WorkspaceContext);
  const { data: programUsage, isSuccess: usageLoaded } =
    useWorkspaceProgramUsage({
      workspaceId: viewedAccount.id,
    });
  if (!usageLoaded) {
    return null;
  }
  const userUsagePercent = programUsage.workspaceCreditsUserUsagePercent;
  const totalUsagePercent = programUsage.workspaceCreditsUsagePercent;
  const startOfNextMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    1,
  );
  return (
    <>
      {userUsagePercent >= ProgramUtilization.High && (
        <StyledAlert
          title="Workspace User Monthly Limit"
          message={
            userUsagePercent >= ProgramUtilization.ReachedQuota ? (
              <span>
                You have reached your personal workspace credit limit. Any of
                your running clusters will be stopped shortly. This will reset
                on {startOfNextMonth.toLocaleDateString()}. You can ask a
                workspace administrator to increase your Coiled credit limit.
              </span>
            ) : (
              <span>
                You are {userUsagePercent.toFixed(1).toString()}% through your
                personal workspace credit limit. This will reset on
                {startOfNextMonth.toLocaleDateString()}. You can ask a workspace
                administrator to increase your Coiled credit limit.
              </span>
            )
          }
          severity={determineAlertLevel(userUsagePercent)}
        />
      )}
      {totalUsagePercent >= ProgramUtilization.High && (
        <StyledAlert
          title="Workspace Monthly Limit"
          message={
            totalUsagePercent >= ProgramUtilization.ReachedQuota ? (
              <span>
                Your workspace has reached its Coiled credit limit. Any running
                clusters will be stopped. This limit will reset on{" "}
                {startOfNextMonth.toLocaleDateString()}. You can ask a workspace
                administrator to increase your Coiled credit limit.
              </span>
            ) : (
              <span>
                Your workspace is {totalUsagePercent.toFixed(1).toString()}%
                through its Coiled credit limit. This limit will reset on{" "}
                {startOfNextMonth.toLocaleDateString()}. You can ask a workspace
                administrator to increase your Coiled credit limit.
              </span>
            )
          }
          severity={determineAlertLevel(totalUsagePercent)}
        />
      )}
    </>
  );
};
const HandleSpendLimitLevels = ({
  organizationId,
}: Props): React.ReactElement | null => {
  const billingUrl = useBillingUrl();
  const { data: programUsage, isSuccess: usageLoaded } =
    useProgramUsage(organizationId);
  if (!usageLoaded) {
    return null;
  }
  const dollarsSpendPercent = programUsage.programDollarsSpendUsagePercent;
  if (dollarsSpendPercent < ProgramUtilization.Medium) {
    return null;
  }

  const alertLevel = determineAlertLevel(dollarsSpendPercent);
  const percent = dollarsSpendPercent.toFixed(1).toString();
  const startOfNextMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    1,
  );
  let message;
  let title;
  if (dollarsSpendPercent >= ProgramUtilization.ReachedQuota) {
    title = "Monthly spent limit reached";
    message = (
      <>
        {`Your workspace has reached the spend limit set by a workspace admin. `}
        {`You won't be able to create new clusters and any pending cluster(s) `}
        {`will be stopped shortly. The spend limit will reset on ${startOfNextMonth.toLocaleDateString()}. `}
        {`A workspace admin can update the spend limit on the `}
        <Link component={NavLink} to={billingUrl}>
          Billing page
        </Link>
        {`.`}
      </>
    );
  } else {
    title = "Monthly spent limit alert";
    message = (
      <>
        {`A workspace admin set a monthly spend limit for this account. `}
        {`You have reached ${percent}% of your spend limit. The spend limit `}
        {`will be reset on ${startOfNextMonth.toLocaleDateString()}. Once the limit has been reached, you `}
        {`won't be able to create new cluster, unless the spend limit is increased. `}
        {`A workspace admin can increase the limit on the `}
        <Link component={NavLink} to={billingUrl}>
          Billing page
        </Link>
        {`.`}
      </>
    );
  }

  return <StyledAlert title={title} message={message} severity={alertLevel} />;
};

const useBillingUrl = (): string => {
  const slug = useWorkspaceContextSlug();
  return `/${Urls.Billing}?account=${slug}`;
};

const AccountLimitsInner = (props: Props): React.ReactElement | null => {
  return (
    <>
      <HandleProgramCreditLevels {...props} />
      <HandleWorkspaceCreditLevels {...props} />
      <HandleSpendLimitLevels {...props} />
    </>
  );
};
export const AccountLimitAlerts = (): React.ReactElement | null => {
  const viewedAccount = useContext(WorkspaceContext);
  if (viewedAccount.organization) {
    return (
      <AccountLimitsInner organizationId={viewedAccount.organization.id} />
    );
  } else {
    return null;
  }
};
