import React, { useState, useEffect } from "react";
import { useAnalytics } from "use-analytics";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AdminPanelSettings,
  Close,
  CreditCard,
  Download,
  FileDownloadOff,
  Payments,
  Receipt,
} from "@mui/icons-material";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  CardErrorContent,
  CardWaitingContent,
} from "../../shared-components/Cards";
import { parseError } from "../../apiUtils";
import {
  useBillingPortalMutation,
  useCheckoutSessionMutation,
} from "../../crud/subscriptions/hooks";
import {
  useOrganizationPaymentHistory,
  useOrganizationPaymentMethod,
  useProgram,
  useProgramUsage,
  useUpdateLimitsMutation,
} from "../../crud/pricing/hooks";
import { useIsOrgAdmin } from "../../crud/account/hooks";
import {
  CompactStyledCell,
  SimpleTable,
} from "../../shared-components/SimpleTable";
import { BillingMethod, FeatureTier, ProgramSchema } from "../../api-client";
import { theme } from "../../theme";
import { getUsedSeats } from "../Team/Team";
import { useOrganization } from "../../crud/organizations/hooks";
import { useOrganizationInvites } from "../../crud/invites/hooks";
import { useWorkspaces } from "../../crud/workspaces/hooks";
import { HookSwitch } from "../../shared-components/HookSwitch";

type DashboardProps = {
  organizationId: number;
  startDate: Date | undefined;
  endDate: Date | undefined;
  onBillingPeriodChange: (startDate: Date, endDate: Date) => void;
};

export const COILED_CREDIT_VALUE = 0.05;

const tierToFancyName = (tier?: FeatureTier): string => {
  switch (tier) {
    case FeatureTier.Essentials:
      return "Essentials";
    case FeatureTier.Professional:
      return "Professional";
    default:
      return "";
  }
};

const billingMethodToFancyName = (billingMethod?: BillingMethod): string => {
  switch (billingMethod) {
    case BillingMethod.Aws:
      return "AWS";
    case BillingMethod.Invoice:
      return "Invoice";
    case BillingMethod.Stripe:
      return "Stripe";
    case BillingMethod.Empty:
      return "No payment method set";
    default:
      return "";
  }
};

export const Dashboard = ({
  organizationId,
  startDate,
  endDate,
  onBillingPeriodChange,
}: DashboardProps): React.ReactElement => {
  const analytics = useAnalytics();
  const isAdmin = useIsOrgAdmin(organizationId);
  const checkoutSessionMutation = useCheckoutSessionMutation();
  const billingPortalMutation = useBillingPortalMutation();
  const {
    data: organizationPaymentMethod,
    isSuccess: paymentMethodSuccess,
    isLoading: paymentMethodLoading,
    error: paymentMethodError,
  } = useOrganizationPaymentMethod(organizationId);
  const {
    data: organizationPaymentHistory,
    isSuccess: paymentHistorySuccess,
    isLoading: paymentHistoryLoading,
    error: paymentHistoryError,
  } = useOrganizationPaymentHistory(organizationId);
  const {
    data: activeProgram,
    isSuccess: programSuccess,
    isLoading: programLoading,
    error,
  } = useProgram(organizationId, startDate, endDate);
  const {
    data: programUsage,
    isSuccess: programUsageSuccess,
    isLoading: programUsageLoading,
  } = useProgramUsage(organizationId, startDate, endDate);
  const { data: orgInvites } = useOrganizationInvites(organizationId);
  const { data: organization } = useOrganization(organizationId);
  const { data: organizationWorkspaces } = useWorkspaces({ organizationId });

  const [billingPeriod, setBillingPeriod] = useState({
    startDate,
    endDate,
  });
  const handleBillingPeriodChange = (event: SelectChangeEvent<string>) => {
    const selectedPeriod = JSON.parse(event.target.value);
    if (selectedPeriod.startDate && selectedPeriod.endDate) {
      const parsedStartDate = new Date(selectedPeriod.startDate);
      const parsedEndDate = new Date(selectedPeriod.endDate);
      setBillingPeriod({
        startDate: parsedStartDate,
        endDate: parsedEndDate,
      });
      onBillingPeriodChange(parsedStartDate, parsedEndDate);
    }
  };

  const updateLimitsMutation = useUpdateLimitsMutation();
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
    setValue,
    watch,
  } = useForm<ProgramSchema>({
    defaultValues: {
      id: 0,
      spendLimitShouldStopInstances: true,
      programDollarsSpendLimit: 0,
      organizationCreditLimit: 0,
    },
  });

  const programDollarsSpendLimit = watch("programDollarsSpendLimit");
  const organizationCreditLimit = watch("organizationCreditLimit");

  useEffect(() => {
    if (organization) {
      reset(organization);
      setValue(
        "organizationCreditLimit",
        (organization.creditLimit ?? 0) *
          (organization?.coiledCreditValue ?? COILED_CREDIT_VALUE),
      );
      setValue("programDollarsSpendLimit", organization.dollarsSpendLimit ?? 0);
    }
  }, [organization, reset, setValue]);

  const onSubmit: SubmitHandler<ProgramSchema> = (data: ProgramSchema) => {
    updateLimitsMutation.mutate(
      {
        organizationId: data.id,
        updateLimitsSchema: {
          spendLimitShouldStopInstances: data.spendLimitShouldStopInstances,
          spendLimit: data.programDollarsSpendLimit,
          organizationCreditLimit:
            (data.organizationCreditLimit ?? 0) /
            (organization?.coiledCreditValue ?? COILED_CREDIT_VALUE),
        },
      },
      {
        onSuccess: () => {},
        onError: (mutateError) => {
          alert("Failed to update limits: " + mutateError.message);
        },
      },
    );
  };

  if (
    programLoading ||
    programUsageLoading ||
    paymentMethodLoading ||
    paymentHistoryLoading
  ) {
    return <CardWaitingContent numRectangles={3} />;
  } else if (error || !programSuccess) {
    return <CardErrorContent error={parseError(error)} />;
  } else if (paymentMethodError || !paymentMethodSuccess) {
    return <CardErrorContent error={parseError(paymentMethodError)} />;
  } else if (paymentHistoryError || !paymentHistorySuccess) {
    return <CardErrorContent error={parseError(paymentHistoryError)} />;
  } else {
    const totalUsageCost =
      programUsage?.programUsageBreakdown.reduce(
        (acc, value) => acc + value.hours * value.price * COILED_CREDIT_VALUE,
        0,
      ) ?? 0;
    const dollarAllotment =
      (activeProgram?.creditAllotment ?? 0) * COILED_CREDIT_VALUE;
    // Make programStartDate default to current month start if null
    // (this should never happen, despite technically being possible)
    const periodStartDate = programUsage?.startDate ?? new Date();
    if (periodStartDate !== programUsage?.startDate) {
      periodStartDate.setUTCDate(1);
      periodStartDate.setUTCHours(0, 0, 0, 0);
    }
    // Make endDate default to current month end if null
    // (this should never happen, despite technically being possible)
    const periodEndDate = programUsage?.endDate ?? new Date();
    if (periodEndDate !== programUsage?.endDate) {
      periodEndDate.setUTCMonth(periodEndDate.getUTCMonth() + 1);
      periodEndDate.setUTCDate(0);
      periodEndDate.setUTCHours(23, 59, 59, 999);
    }
    if (!billingPeriod.startDate || !billingPeriod.endDate) {
      setBillingPeriod({
        startDate: periodStartDate,
        endDate: periodEndDate,
      });
    }
    const utcDateFormat = new Intl.DateTimeFormat(undefined, {
      month: "long",
      year: "numeric",
      day: "numeric",
      timeZone: "UTC",
    });
    const creditDiscount =
      COILED_CREDIT_VALUE -
      (activeProgram?.coiledCreditValue ?? COILED_CREDIT_VALUE);
    const percentDiscount = creditDiscount / COILED_CREDIT_VALUE;
    const totalDiscount = totalUsageCost * percentDiscount;
    const balance = Math.max(
      totalUsageCost - dollarAllotment - totalDiscount,
      0,
    );
    const remainingCredit = Math.max(
      dollarAllotment - (totalUsageCost - totalDiscount),
      0,
    );
    return (
      <Box key={`dashboard-${organizationId}`}>
        <Grid container spacing={2 as number}>
          {/* Usage Section */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{ display: "flex", flexDirection: "column" }}
            key={`usage-${organizationId}-${billingPeriod.startDate?.toDateString()}-${billingPeriod.endDate?.toDateString()}`}
          >
            {programUsageLoading || programLoading ? (
              <CardWaitingContent numRectangles={1} />
            ) : (
              <Card sx={{ flex: 1 }}>
                <CardHeader
                  avatar={
                    <Receipt sx={{ color: theme.palette.secondary.main }} />
                  }
                  title="Usage"
                />
                <CardContent>
                  <Typography variant="h3">Plan</Typography>
                  <SimpleTable
                    loading={
                      !programSuccess ||
                      !programUsageSuccess ||
                      programLoading ||
                      programUsageLoading
                    }
                    compact
                    aligns={["left", "right"]}
                    widths={["50%", "50%"]}
                    text={[
                      [
                        "Billing Period",
                        <Select
                          key="billing-period"
                          onChange={handleBillingPeriodChange}
                          value={JSON.stringify(
                            activeProgram.billingPeriods?.find(
                              (bp) =>
                                bp.startDate?.toISOString() ===
                                  billingPeriod.startDate?.toISOString() &&
                                bp.endDate?.toISOString() ===
                                  billingPeriod.endDate?.toISOString(),
                            ) ?? activeProgram.billingPeriods?.[0],
                          )}
                          sx={{
                            padding: 0,
                            "& .MuiSelect-select": {
                              padding: "2px 4px 2px 4px",
                            },
                          }}
                        >
                          {(
                            activeProgram.billingPeriods ?? [
                              {
                                startDate: periodStartDate,
                                endDate: periodEndDate,
                              },
                            ]
                          ).map((bp) => (
                            <MenuItem
                              key={`${bp?.startDate?.toString()}-${bp?.endDate?.toString()}`}
                              value={JSON.stringify({
                                startDate: bp.startDate,
                                endDate: bp.endDate,
                              })}
                            >
                              {utcDateFormat.formatRange(
                                bp.startDate ?? periodStartDate,
                                bp.endDate ?? periodEndDate,
                              )}
                            </MenuItem>
                          ))}
                        </Select>,
                      ],
                      [
                        "Subscription Tier",
                        tierToFancyName(activeProgram?.tier),
                        "",
                      ],
                      [
                        `${activeProgram?.tier === FeatureTier.Professional ? "Prepurchased" : "Remaining Free"} Usage`,
                        `${remainingCredit.toLocaleString(undefined, { style: "currency", currency: "USD" })}`,
                      ],
                      [
                        "Seats",
                        <Tooltip
                          key="seat-limit-tooltip"
                          title={
                            <>
                              Need more?{" "}
                              <Link href="mailto:support@coiled.io">
                                Contact us
                              </Link>
                            </>
                          }
                        >
                          <Typography key="seat-limit">
                            {getUsedSeats(
                              organization,
                              orgInvites,
                            ).toLocaleString()}
                            {" / "}
                            {(organization?.memberLimit ?? 1000) === 1000
                              ? "∞"
                              : organization?.memberLimit.toLocaleString()}
                          </Typography>
                        </Tooltip>,
                      ],
                      [
                        "Workspaces",
                        <Tooltip
                          key="workspace-limit-tooltip"
                          title={
                            <>
                              Need more?{" "}
                              <Link href="mailto:support@coiled.io">
                                Contact us
                              </Link>
                            </>
                          }
                        >
                          <Typography key="workspace-limit">
                            {(
                              organizationWorkspaces?.length ?? 0
                            ).toLocaleString()}
                            {" / "}
                            {organization?.workspaceLimit.toLocaleString()}
                          </Typography>
                        </Tooltip>,
                      ],
                    ]}
                  />
                  {(programUsage?.programUsageBreakdown?.length ?? 0) > 0 && (
                    <>
                      <Typography variant="h3" sx={{ marginTop: "16px" }}>
                        Compute
                      </Typography>
                      <SimpleTable
                        loading={
                          programLoading ||
                          programUsageLoading ||
                          !programSuccess ||
                          !programUsageSuccess
                        }
                        text={(programUsage?.programUsageBreakdown ?? []).map(
                          (value) => [
                            `${value.name} Hours`,
                            value.hours.toLocaleString(),
                            <Close
                              key={`close-icon-${value.name}`}
                              fontSize="inherit"
                              sx={{ verticalAlign: "-10%" }}
                            />,
                            (
                              (value.price ?? 0) * COILED_CREDIT_VALUE
                            ).toLocaleString(undefined, {
                              style: "currency",
                              currency: "USD",
                            }),
                            "=",
                            (
                              value.hours *
                              value.price *
                              COILED_CREDIT_VALUE
                            ).toLocaleString(undefined, {
                              style: "currency",
                              currency: "USD",
                            }),
                          ],
                        )}
                        compact
                        widths={["50%", "15%", "5%", "10%", "5%", "15%"]}
                        aligns={[
                          "left",
                          "right",
                          "right",
                          "right",
                          "right",
                          "right",
                        ]}
                      />
                    </>
                  )}
                  <Typography variant="h3" sx={{ marginTop: "16px" }}>
                    Total Charges
                  </Typography>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <CompactStyledCell width="80%" variant="head">
                          Total Compute
                        </CompactStyledCell>
                        <CompactStyledCell width="5%" align="right" />
                        <CompactStyledCell width="15%" align="right">
                          {totalUsageCost?.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          })}
                        </CompactStyledCell>
                      </TableRow>
                      {totalDiscount > 0 && (
                        <TableRow>
                          <CompactStyledCell width="80%" variant="head">
                            {percentDiscount.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                              style: "percent",
                            })}{" "}
                            Discount
                          </CompactStyledCell>
                          <CompactStyledCell align="right" width="5%">
                            –
                          </CompactStyledCell>
                          <CompactStyledCell align="right" width="15%">
                            {totalDiscount.toLocaleString(undefined, {
                              style: "currency",
                              currency: "USD",
                            })}
                          </CompactStyledCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "1px 4px 1px 4px",
                            whiteSpace: "pre-line",
                            borderBottom: "2px solid",
                          }}
                          width="80%"
                          variant="head"
                        >
                          {activeProgram?.tier !== FeatureTier.Professional
                            ? "Included Free Usage"
                            : "Prepurchased Usage"}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "1px 4px 1px 4px",
                            whiteSpace: "pre-line",
                            borderBottom: "2px solid",
                          }}
                          align="right"
                          width="5%"
                        >
                          –
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "1px 4px 1px 4px",
                            whiteSpace: "pre-line",
                            borderBottom: "2px solid",
                          }}
                          align="right"
                          width="15%"
                        >
                          {dollarAllotment.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          })}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <CompactStyledCell width="80%" variant="head">
                          Balance
                        </CompactStyledCell>
                        <CompactStyledCell width="5%" align="right" />
                        <CompactStyledCell
                          width="15%"
                          align="right"
                          style={{
                            color:
                              balance > 0
                                ? theme.palette.secondary.main
                                : "inherit",
                            fontWeight:
                              balance > 0
                                ? theme.typography.fontWeightMedium || "bolder"
                                : "inherit",
                          }}
                        >
                          {balance.toLocaleString(undefined, {
                            style: "currency",
                            currency: "USD",
                          })}
                        </CompactStyledCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            )}
          </Grid>
          {/* Limit Section */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Card sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <CardHeader
                avatar={
                  <AdminPanelSettings
                    sx={{ color: theme.palette.secondary.main }}
                  />
                }
                title="Spend Controls"
              />
              <CardContent sx={{ flex: 1 }}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Stack spacing="auto" useFlexGap sx={{ flexGrow: 1 }}>
                    <SimpleTable
                      aligns={["left", "right"]}
                      widths={["60%", "40%"]}
                      text={[
                        [
                          "Total Compute",
                          <Tooltip
                            key="total-usage-tooltip"
                            title="This is approximately equal to cloud provider spend per billing period"
                          >
                            <TextField
                              key={`org-credit-limit-${organizationId}`}
                              fullWidth
                              {...register("organizationCreditLimit", {
                                valueAsNumber: true,
                              })}
                              variant="outlined"
                              size="small"
                              value={(organizationCreditLimit ?? 0).toFixed(0)}
                            />
                          </Tooltip>,
                        ],
                        [
                          "Balance",
                          <Tooltip
                            key="balance-tooltip"
                            title="We will charge you no more than this amount in a billing period"
                          >
                            <TextField
                              key={`org-dollar-spend-limit-${organizationId}`}
                              fullWidth
                              {...register("programDollarsSpendLimit", {
                                valueAsNumber: true,
                              })}
                              variant="outlined"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {programDollarsSpendLimit === 0
                                      ? "(zero means no limit)"
                                      : ""}
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Tooltip>,
                        ],
                        [
                          "Stop instances when limits are hit",
                          <Tooltip
                            key="spend-limit-tooltip"
                            title="This setting controls whether any running instances will be stopped as soon as limits (organization, workspace, or user) are hit. If we do not stop running instances, this will result in charges beyond your configured spend limits. Creating new clusters will always be prevented once spend limit is hit."
                          >
                            <HookSwitch
                              key="spend-limit-switch"
                              control={control}
                              name="spendLimitShouldStopInstances"
                            />
                          </Tooltip>,
                        ],
                      ]}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <LoadingButton
                        key="spend-control-save-button"
                        type="submit"
                        variant="primary"
                        disabled={!isAdmin || !isDirty}
                        loading={updateLimitsMutation.isLoading}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </Stack>
                </form>
              </CardContent>
            </Card>
          </Grid>
          {/* Method Section */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {programLoading || paymentMethodLoading ? (
              <CardWaitingContent numRectangles={1} />
            ) : (
              <Card sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <CardHeader
                  avatar={
                    <CreditCard sx={{ color: theme.palette.secondary.main }} />
                  }
                  title="Method"
                />
                <CardContent
                  sx={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <SimpleTable
                    aligns={["left", "right"]}
                    loading={paymentMethodLoading}
                    text={
                      // Display brand, credit card, and expires if billingMethod is stripe, else display billingMethod as type and nothing else
                      organizationPaymentMethod?.billingMethod === "stripe" &&
                      organizationPaymentMethod?.creditCard?.brand
                        ? [
                            [
                              "Stripe Credit Card",
                              `${organizationPaymentMethod?.creditCard?.brand ?? "Card"} ending in ${organizationPaymentMethod?.creditCard?.last4 ?? "XXXX"}`,
                            ],
                            [
                              "Expiration Date",
                              organizationPaymentMethod?.creditCard?.expMonth
                                ? `${organizationPaymentMethod?.creditCard?.expMonth}/${organizationPaymentMethod?.creditCard?.expYear}`
                                : "MM/YYYY",
                            ],
                            [
                              "Name",
                              organizationPaymentMethod?.billingContact?.name ??
                                "",
                            ],
                            [
                              "Billing Address",
                              // Display address as a single string if line1 is not undefined
                              // Otherwise, display ""
                              organizationPaymentMethod?.billingContact?.address
                                ?.line1
                                ? `${organizationPaymentMethod?.billingContact?.address?.line1 ?? ""}\n${organizationPaymentMethod?.billingContact?.address?.line2 ?? ""}\n${organizationPaymentMethod?.billingContact?.address?.city ?? ""}, ${organizationPaymentMethod?.billingContact?.address?.state ?? ""} ${organizationPaymentMethod?.billingContact?.address?.postalCode ?? ""}`
                                : "",
                            ],
                            [
                              "Email",
                              organizationPaymentMethod?.billingContact
                                ?.email ?? "",
                            ],
                          ]
                        : [
                            [
                              "Type",
                              `${billingMethodToFancyName(organizationPaymentMethod?.billingMethod)}`,
                            ],
                            [
                              "Name",
                              organizationPaymentMethod?.billingContact?.name ??
                                "",
                            ],
                            [
                              "Billing Address",
                              // Display address as a single string if line1 is not undefined
                              // Otherwise, display ""
                              organizationPaymentMethod?.billingContact?.address
                                ?.line1
                                ? `${organizationPaymentMethod?.billingContact?.address?.line1 ?? ""}\n${organizationPaymentMethod?.billingContact?.address?.line2 ?? ""}\n${organizationPaymentMethod?.billingContact?.address?.city ?? ""}, ${organizationPaymentMethod?.billingContact?.address?.state ?? ""} ${organizationPaymentMethod?.billingContact?.address?.postalCode ?? ""}`
                                : "",
                            ],
                            [
                              "Email",
                              organizationPaymentMethod?.billingContact
                                ?.email ?? "",
                            ],
                          ]
                    }
                  />
                  <Box sx={{ flexGrow: 1 }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    {((activeProgram.billingMethod === BillingMethod.Aws ||
                      activeProgram.billingMethod ===
                        BillingMethod.Invoice) && (
                      <Tooltip title="Contact us to manage this">
                        <span>
                          <LoadingButton
                            disabled
                            loading={paymentMethodLoading}
                          >
                            Edit
                          </LoadingButton>
                        </span>
                      </Tooltip>
                    )) ||
                      (activeProgram.billingMethod === BillingMethod.Stripe && (
                        <Tooltip
                          title={
                            isAdmin
                              ? "Manage your subscription"
                              : "Contact your organization admin to manage this subscription"
                          }
                        >
                          <span>
                            <LoadingButton
                              disabled={!isAdmin}
                              // using isIdle here because we still want to be "loading" while
                              // redirecting
                              loading={!billingPortalMutation.isIdle}
                              onClick={() => {
                                analytics.track("manage-subscription-click", {
                                  organizationId,
                                });
                                billingPortalMutation.mutateAsync(
                                  {
                                    organizationId,
                                  },
                                  {
                                    onSuccess: (data) => {
                                      window.location.href = data;
                                    },
                                  },
                                );
                              }}
                            >
                              Manage
                            </LoadingButton>
                          </span>
                        </Tooltip>
                      )) ||
                      (activeProgram.billingMethod === BillingMethod.Empty && (
                        <Tooltip
                          title={
                            isAdmin
                              ? "Add a credit card to allow using more than your monthly allowance"
                              : "Contact your organization admin to add a credit card to your subscription"
                          }
                        >
                          <span>
                            <LoadingButton
                              disabled={!isAdmin}
                              // using isIdle here because we still want to be "loading" while
                              // redirecting
                              loading={!checkoutSessionMutation.isIdle}
                              onClick={() => {
                                analytics.track("upgrade-subscription-click", {
                                  organizationId,
                                });
                                checkoutSessionMutation.mutateAsync(
                                  {
                                    organizationId,
                                  },
                                  {
                                    onSuccess: (data) => {
                                      window.location.href = data;
                                    },
                                  },
                                );
                              }}
                            >
                              Add Credit Card
                            </LoadingButton>
                          </span>
                        </Tooltip>
                      ))}
                  </Box>
                </CardContent>
              </Card>
            )}
          </Grid>

          {/* History Section */}
          <Grid item xs={12}>
            {paymentHistoryLoading ? (
              <CardWaitingContent numRectangles={1} />
            ) : (
              <Card>
                <CardHeader
                  avatar={
                    <Payments sx={{ color: theme.palette.secondary.main }} />
                  }
                  title="History"
                />
                <CardContent>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Payment Method</TableCell>
                          <TableCell>Download</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paymentHistorySuccess &&
                          organizationPaymentHistory?.payments?.map((row) => (
                            <TableRow key={row.date.toString()}>
                              <TableCell>
                                {utcDateFormat.format(row.date)}
                              </TableCell>
                              <TableCell>
                                {(row.amount / 100).toLocaleString(undefined, {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </TableCell>
                              <TableCell>
                                {billingMethodToFancyName(
                                  row.paymentMethod?.billingMethod,
                                )}
                              </TableCell>
                              <TableCell>
                                <Tooltip title="Download invoice as PDF">
                                  <Link href={row.link ?? ""}>
                                    {(row.link && <Download />) || (
                                      <FileDownloadOff />
                                    )}
                                  </Link>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
};
