/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AWSBillingSchema } from './AWSBillingSchema';
import {
    AWSBillingSchemaFromJSON,
    AWSBillingSchemaFromJSONTyped,
    AWSBillingSchemaToJSON,
} from './AWSBillingSchema';
import type { BillingContactSchema } from './BillingContactSchema';
import {
    BillingContactSchemaFromJSON,
    BillingContactSchemaFromJSONTyped,
    BillingContactSchemaToJSON,
} from './BillingContactSchema';
import type { BillingMethod } from './BillingMethod';
import {
    BillingMethodFromJSON,
    BillingMethodFromJSONTyped,
    BillingMethodToJSON,
} from './BillingMethod';
import type { CreditCardSchema } from './CreditCardSchema';
import {
    CreditCardSchemaFromJSON,
    CreditCardSchemaFromJSONTyped,
    CreditCardSchemaToJSON,
} from './CreditCardSchema';

/**
 * 
 * @export
 * @interface PaymentMethodSchema
 */
export interface PaymentMethodSchema {
    /**
     * 
     * @type {BillingContactSchema}
     * @memberof PaymentMethodSchema
     */
    billingContact?: BillingContactSchema;
    /**
     * 
     * @type {CreditCardSchema}
     * @memberof PaymentMethodSchema
     */
    creditCard?: CreditCardSchema;
    /**
     * 
     * @type {AWSBillingSchema}
     * @memberof PaymentMethodSchema
     */
    awsInfo?: AWSBillingSchema;
    /**
     * 
     * @type {BillingMethod}
     * @memberof PaymentMethodSchema
     */
    billingMethod?: BillingMethod;
}

/**
 * Check if a given object implements the PaymentMethodSchema interface.
 */
export function instanceOfPaymentMethodSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentMethodSchemaFromJSON(json: any): PaymentMethodSchema {
    return PaymentMethodSchemaFromJSONTyped(json, false);
}

export function PaymentMethodSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingContact': !exists(json, 'billing_contact') ? undefined : BillingContactSchemaFromJSON(json['billing_contact']),
        'creditCard': !exists(json, 'credit_card') ? undefined : CreditCardSchemaFromJSON(json['credit_card']),
        'awsInfo': !exists(json, 'aws_info') ? undefined : AWSBillingSchemaFromJSON(json['aws_info']),
        'billingMethod': !exists(json, 'billing_method') ? undefined : BillingMethodFromJSON(json['billing_method']),
    };
}

export function PaymentMethodSchemaToJSON(value?: PaymentMethodSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billing_contact': BillingContactSchemaToJSON(value.billingContact),
        'credit_card': CreditCardSchemaToJSON(value.creditCard),
        'aws_info': AWSBillingSchemaToJSON(value.awsInfo),
        'billing_method': BillingMethodToJSON(value.billingMethod),
    };
}

