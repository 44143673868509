import React, { ReactElement } from "react";
import {
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

const StyledCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    borderBottom: "none",
    whiteSpace: "pre-line",
  },
});

export const CompactStyledCell = styled(StyledCell)({
  "&.MuiTableCell-root": {
    padding: "1px 4px 1px 4px",
  },
});

export const DefaultStyledCell = styled(StyledCell)({
  "&.MuiTableCell-root": {
    padding: "8px 4px 8px 4px",
  },
});

export const BoldCell = styled(DefaultStyledCell)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium || "bold",
}));

export const BoldCompactCell = styled(CompactStyledCell)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium || "bold",
}));

type SimpleTableProps = {
  text: (string | React.ReactNode)[][];
  loading?: boolean;
  compact?: boolean;
  widths?: string[];
  aligns?: ("left" | "right" | "center")[];
  head?: string | React.ReactNode;
};

export const SimpleTable = ({
  text,
  loading = false,
  compact = false,
  aligns = [],
  widths = [],
}: SimpleTableProps): ReactElement => {
  const CellComponent = compact ? CompactStyledCell : DefaultStyledCell;
  const BoldCellComponent = compact ? BoldCompactCell : BoldCell;

  if (text && text.length > 0) {
    if (widths.length && widths.length !== text[0].length) {
      throw new Error(
        "widths must have the same number of elements as the text rows",
      );
    }

    if (aligns.length && aligns.length !== text[0].length) {
      throw new Error(
        "aligns must have the same number of elements as the text rows",
      );
    }
  }

  return (
    <Table>
      <TableBody>
        {text.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <React.Fragment key={cellIndex}>
                {cellIndex === 0 ? (
                  <BoldCellComponent
                    align={aligns.length > 0 ? aligns[cellIndex] : undefined}
                    width={widths.length > 0 ? widths[cellIndex] : undefined}
                  >
                    <Typography variant="body1">{cell}</Typography>
                  </BoldCellComponent>
                ) : (
                  <CellComponent
                    align={aligns.length > 0 ? aligns[cellIndex] : undefined}
                    width={widths.length > 0 ? widths[cellIndex] : undefined}
                  >
                    {" "}
                    {loading ? (
                      <Typography>
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </Typography>
                    ) : (
                      cell
                    )}
                  </CellComponent>
                )}
              </React.Fragment>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
