/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentMethodSchema } from './PaymentMethodSchema';
import {
    PaymentMethodSchemaFromJSON,
    PaymentMethodSchemaFromJSONTyped,
    PaymentMethodSchemaToJSON,
} from './PaymentMethodSchema';

/**
 * 
 * @export
 * @interface PaymentHistoryItemSchema
 */
export interface PaymentHistoryItemSchema {
    /**
     * 
     * @type {Date}
     * @memberof PaymentHistoryItemSchema
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof PaymentHistoryItemSchema
     */
    amount: number;
    /**
     * 
     * @type {PaymentMethodSchema}
     * @memberof PaymentHistoryItemSchema
     */
    paymentMethod: PaymentMethodSchema;
    /**
     * 
     * @type {string}
     * @memberof PaymentHistoryItemSchema
     */
    link?: string;
}

/**
 * Check if a given object implements the PaymentHistoryItemSchema interface.
 */
export function instanceOfPaymentHistoryItemSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "paymentMethod" in value;

    return isInstance;
}

export function PaymentHistoryItemSchemaFromJSON(json: any): PaymentHistoryItemSchema {
    return PaymentHistoryItemSchemaFromJSONTyped(json, false);
}

export function PaymentHistoryItemSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentHistoryItemSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'amount': json['amount'],
        'paymentMethod': PaymentMethodSchemaFromJSON(json['payment_method']),
        'link': !exists(json, 'link') ? undefined : json['link'],
    };
}

export function PaymentHistoryItemSchemaToJSON(value?: PaymentHistoryItemSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date.toISOString()),
        'amount': value.amount,
        'payment_method': PaymentMethodSchemaToJSON(value.paymentMethod),
        'link': value.link,
    };
}

