/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreditCardSchema
 */
export interface CreditCardSchema {
    /**
     * 
     * @type {string}
     * @memberof CreditCardSchema
     */
    last4?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCardSchema
     */
    brand?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditCardSchema
     */
    expYear?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditCardSchema
     */
    expMonth?: number;
}

/**
 * Check if a given object implements the CreditCardSchema interface.
 */
export function instanceOfCreditCardSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreditCardSchemaFromJSON(json: any): CreditCardSchema {
    return CreditCardSchemaFromJSONTyped(json, false);
}

export function CreditCardSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditCardSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'last4': !exists(json, 'last4') ? undefined : json['last4'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'expYear': !exists(json, 'exp_year') ? undefined : json['exp_year'],
        'expMonth': !exists(json, 'exp_month') ? undefined : json['exp_month'],
    };
}

export function CreditCardSchemaToJSON(value?: CreditCardSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last4': value.last4,
        'brand': value.brand,
        'exp_year': value.expYear,
        'exp_month': value.expMonth,
    };
}

