import React, { ReactNode, useMemo, useState } from "react";
import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Alert,
} from "@mui/material/";
import { BreadCrumbPart, Page } from "../../shared-components/Page";
import { GroupAdd, PersonAdd, Workspaces } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Urls } from "../../domain/urls";
import { WorkspacesList } from "./WorkspacesList";
import {
  OrganizationInviteSchema,
  OrganizationSchema,
  OrganizationScopeSchema,
} from "../../api-client";
import { useWorkspaces } from "../../crud/workspaces/hooks";
import { useIsAnyKindOfAdmin, useIsOrgAdmin } from "../../crud/account/hooks";
import { OrganizationTeamGrid } from "./OrganizationMembers";
import {
  VerticalTabPanel,
  VerticalTabs,
} from "../../shared-components/VerticalTabs";
import { OrganizationSelector } from "../../shared-components/OrganizationSelector";
import { SelectSomething } from "../../shared-components/SelectSomething";
import { useOrganization } from "../../crud/organizations/hooks";
import { useOrganizationInvites } from "../../crud/invites/hooks";

type AddButtonProps = {
  organizationId: number;
};

export const getUsedSeats = (
  organization?: OrganizationSchema,
  orgInvites?: OrganizationInviteSchema[],
): number => {
  return (orgInvites?.length || 0) + (organization?.memberSet.length || 0);
};

export const haveReachedMemberLimit = (
  organization?: OrganizationSchema,
  orgInvites?: OrganizationInviteSchema[],
): boolean => {
  const usedSeats = getUsedSeats(organization, orgInvites);
  const memberLimit = organization?.memberLimit || 0;

  return usedSeats + 1 > memberLimit;
};

export const seatLimitAlertText = (
  organization?: OrganizationSchema,
  orgInvites?: OrganizationInviteSchema[],
): ReactNode | null => {
  const usedSeats =
    (orgInvites?.length || 0) + (organization?.memberSet.length || 0);
  const memberLimit = organization?.memberLimit || 0;

  const alertText =
    organization && usedSeats >= 0.5 * memberLimit ? (
      <>
        Your organization is using {usedSeats} of {memberLimit} allowed seats.
        Please email{" "}
        <Link to="mailto:support@coiled.io">support@coiled.io</Link> to discuss
        pricing for more seats.{" "}
      </>
    ) : null;

  return alertText;
};

const AddButton = ({ organizationId }: AddButtonProps): React.ReactElement => {
  const { data: workspaces } = useWorkspaces({ organizationId });
  const isAdmin = useIsAnyKindOfAdmin(
    organizationId,
    workspaces?.map((w) => w.slug),
  );
  const isOrgAdmin = useIsOrgAdmin(organizationId);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <Button onClick={handleClick}>Add</Button>
      <Menu onClose={handleClose} open={open} anchorEl={anchorEl}>
        <Tooltip
          arrow
          placement="right"
          title={
            !isAdmin
              ? "You must be an organization or workspace admin to add team mates."
              : ""
          }
        >
          <div>
            <MenuItem
              component={Link}
              disabled={!isAdmin}
              to={`/${Urls.Team}/${organizationId}/add-member`}
            >
              <ListItemIcon>
                <PersonAdd />
              </ListItemIcon>
              Teammate
            </MenuItem>
          </div>
        </Tooltip>
        <Tooltip
          arrow
          placement="right"
          title={
            !isOrgAdmin
              ? "You must be an organization admin to add workspaces."
              : ""
          }
        >
          <div>
            <MenuItem
              disabled={!isOrgAdmin}
              component={Link}
              to={`/${Urls.Team}/${organizationId}/create-workspace`}
            >
              <ListItemIcon>
                <Workspaces />
              </ListItemIcon>
              Workspace
            </MenuItem>
          </div>
        </Tooltip>
        <MenuItem component={Link} to={`/${Urls.Team}/create-organization`}>
          <ListItemIcon>
            <GroupAdd />
          </ListItemIcon>
          Organization
        </MenuItem>
      </Menu>
    </>
  );
};

export const TeamView = (): React.ReactElement => {
  const [selectedOrg, setSelectedOrg] = useState<
    OrganizationScopeSchema | undefined
  >(undefined);
  const breadCrumbs = useMemo<BreadCrumbPart[]>(() => {
    return selectedOrg
      ? [{ text: "Organization" }, { text: selectedOrg.name }]
      : [];
  }, [selectedOrg]);
  const { data: workspaces } = useWorkspaces({
    organizationId: selectedOrg?.id,
  });

  const { data: orgInvites } = useOrganizationInvites(selectedOrg?.id);
  const { data: organization } = useOrganization(selectedOrg?.id);

  const alertText = seatLimitAlertText(organization, orgInvites);

  return (
    <Page title="Team" breadCrumbs={breadCrumbs}>
      <Stack spacing={2}>
        {alertText && (
          <Alert severity="info" sx={{ marginBottom: "1rem" }}>
            {alertText}
          </Alert>
        )}
        <Stack direction="row" alignItems={"center"}>
          {selectedOrg && (
            <Tooltip
              open={workspaces?.length === 0}
              title={"Add a workspace to get started."}
              arrow
              placement="right"
            >
              <div>
                <AddButton organizationId={selectedOrg?.id} />
              </div>
            </Tooltip>
          )}
          <div style={{ marginLeft: "auto" }}>
            <OrganizationSelector onChange={(e) => setSelectedOrg(e)} />
          </div>
        </Stack>
        {selectedOrg && (
          <VerticalTabs tabsWidth="120px">
            <VerticalTabPanel value="Workspaces" withPaper={false}>
              {selectedOrg && (
                <WorkspacesList organizationId={selectedOrg.id} />
              )}
            </VerticalTabPanel>
            <VerticalTabPanel value="Team" withPaper={false}>
              {selectedOrg && (
                <OrganizationTeamGrid organizationId={selectedOrg.id} />
              )}
            </VerticalTabPanel>
          </VerticalTabs>
        )}
        {!selectedOrg && (
          <SelectSomething message="Select an organization to view team information." />
        )}
      </Stack>
    </Page>
  );
};
