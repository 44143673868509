/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AWSBillingSchema
 */
export interface AWSBillingSchema {
    /**
     * 
     * @type {string}
     * @memberof AWSBillingSchema
     */
    last5?: string;
}

/**
 * Check if a given object implements the AWSBillingSchema interface.
 */
export function instanceOfAWSBillingSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AWSBillingSchemaFromJSON(json: any): AWSBillingSchema {
    return AWSBillingSchemaFromJSONTyped(json, false);
}

export function AWSBillingSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AWSBillingSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'last5': !exists(json, 'last5') ? undefined : json['last5'],
    };
}

export function AWSBillingSchemaToJSON(value?: AWSBillingSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last5': value.last5,
    };
}

